<template>
  <div>
    <section class="main services">
      <div class="hero parallax"></div>

      <h2>{{ currentRouteName }}</h2>

      <article>
        <div class="thumb"></div>
        <p class="intro">
          GammaTeam Security provides public <router-link to="/">events security</router-link>services, including professional safekeeping personnel, consultation, and celebrity protection for large-scale events such as trade shows, concerts, outdoor festivals, and sporting events; and for smaller-scale gatherings such as shareholder meetings and conferences.<br /><br />

          We are paying close attention by focusing on controlled entry, security searches, <router-link to="/services">crowd management</router-link>, ushers and theft prevention. Our team analyzes each engagement and provides specialized services based on unique requirements of each event's program and location, ensuring that your venue remains orderly, safe, and free of crime.<br /><br />

          We begin each commitment by meeting with the client to carefully review the security needs at each stage of the event set up, peak attendance, and break down. Based on our findings, we develop a meticulous security plan and select a security team with the right experience and training for each task and post.<br /><br />

          GammaTeam Security specializes in providing security for: <span class="event" v-for="(event, i) in events" :key="i">{{ event }}</span>
        </p>
        <div class="flex-container">
          <div class="services_subsection" v-for="(item, i) in items" :key="i">
            <h3>{{ item.label }}</h3>
            {{ item.text }}
          </div>
        </div>
      </article>

      <call-to-action :link_back=true></call-to-action>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Special_Event_Security',
  data: () => ({
    items: [],
    events: [
      'Concerts', 'Festivals', 'Trade Shows', 'Red carpets', 'Sporting events', 'Parades', "Share holder's meetings", 'Conferences'
    ]
  })
}
</script>

<style scoped>
section.main article div.thumb {
  background-image: url("/images/services/event.jpg");
}
section.main article div.services_subsection {
  width: 48%;
}
section.main article p.intro span.event {
  text-transform: capitalize;
  font-weight: 600;
}
section.main article p.intro span.event::after {
  content: ', ';
  display: inline;
}
section.main article p.intro span.event:last-child::after {
  content: '';
}
</style>
